<template>
  <article id="about" class="layout-panel">
    <section class="layout-panel__item -panel-1">
      <h3 class="layout-panel__title">Théatre</h3>
      <p>La conception des décors pour une pièce de théâtre est essentielle au succès de la représentation elle-même. Un soin infini est apporté à sa conception.</p>
    </section>

    <section class="layout-panel__item -panel-2">
      <h3 class="layout-panel__title">Événements</h3>
      <p>Le décor est l'image de l'événement. Que se soit pour un spectacle, une exposition, un événement corporatif ou autre... Nos décors ne laisse jamais perosnne indifférent.</p>
    </section>

    <section class="layout-panel__item -panel-3">
      <h3 class="layout-panel__title">Télévision</h3>
      <p>Le décor d'une émission télévisée est nécessaire et incontournable. Sa conception est élaborée en fonction de la thématique et de l'esthétisme du projet.</p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeServices'
}
</script>
