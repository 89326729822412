<template>
  <section>
    <HomeHeader />
    <HomeServices />
    <HomeAbout />
    <HomeExpertise />
    <!-- <HomeProjects /> -->
    <HomeContact />
    <HomeClients />
  </section>
</template>

<script>
import * as partials from './partials'

export default {
  name: 'Home',
  isPage: true,
  components: { ...partials },
  watch: {
    '$route.hash'(hash) {
      if (!hash) this.$scroll.toTop(50, 350)
      else this.$scroll.toTarget(this.$route.hash, 50, 350)
    }
  }
}
</script>
