<template>
  <div class="layout">
    <Transition name="layout__view" mode="out-in">
      <RouterView :key="$route.name" class="layout__view" />
    </Transition>

    <NavigationTop />
    <NavigationFooter />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
