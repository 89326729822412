export default {
  en: {
    common: {
      bookNow: 'Book now',
      bookAppointment: 'Book an appointment',
      findOutMore: 'Find out more',
      newsLetter: "S'inscrire à l'infolettre",
      visitQigong: 'Visit the Qigong Site',
      next: 'Next',
      emailAddress: 'Email Address',
      firstName: 'First name',
      lastName: 'Last name'
    },
    nav: {
      formations: 'Formations'
    },
    address: {
      laurierMetro: 'Laurier Station'
    },
    qigong: {
      title: 'Sean offers Qigong courses and workshops to help you integrate health practices into your everyday life.',
      subtitle: 'Become proactive in your recovery and health maintenance. ',
      content:
        'As a qigong teacher, Sean Laflamme endeavours to bridge the gap between ancient Chinese teachings and modern audiences. Placing great importance on the preservation and transmission of this sacred knowledge, he firmly believes in these time-honoured practices and traditions that continue to prove their importance as they resonate deeply within those who explore it.'
    }
  },
  fr: {
    common: {
      bookNow: 'Rendez-Vous',
      bookAppointment: 'Prendre rendez-vous',
      findOutMore: 'En savoir plus',
      newsLetter: "S'inscrire à l'infolettre",
      visitQigong: 'Visitez le Site Qigong',
      next: 'Suivant',
      emailAddress: 'Adresse courriel',
      firstName: 'Prénom',
      lastName: 'Nom'
    },
    nav: {
      formations: 'Formations'
    },
    address: {
      laurierMetro: 'Station Laurier'
    },
    qigong: {
      title:
        'Sean offre des cours et des formations de Qigong pour vous aider à intégrer des pratiques de santé au quotidien.',
      subtitle: 'Devenez proactif dans votre guérison et le maintien de votre santé.',
      content:
        'En tant qu’enseignant de qigong, Sean Laflamme œuvre à faire un pont entre les enseignements chinois anciens et le public moderne. Accordant une grande importance à la transmission et la préservation de connaissances millénaires, il croit fermement dans la valeur de ces pratiques qui sont honorées par le temps et qui continuent à faire leurs preuves en résonnant profondément dans l’esprit des pratiquants.'
    }
  }
}
