<template>
  <section class="layout-section">
    <h2 class="layout-section__title">Merci pour votre confiance</h2>
    <p>Merci à nos pertenaires de nous faire confiance depuis toutes ces années.</p>

    <transition-group tag="div" class="logo-slider" name="logo-slider">
      <img v-for="logo in activeLogos" :key="logo.src" :src="logo.src" :class="['logo-slider__item', logo.modifier]" />
    </transition-group>
  </section>
</template>

<script>
export default {
  name: 'HomeClients',
  data() {
    return {
      logos: [
        { src: '/images/clients/trident.png' },
        { src: '/images/clients/feq.png', modifier: '-short' },
        { src: '/images/clients/mnbaq.png'},
        { src: '/images/clients/tva.png', modifier: '-short' },
        { src: '/images/clients/bordee.png ' },
        { src: '/images/clients/musee-de-la-civilisation.png' },
        { src: '/images/clients/opera-quebec.png', modifier: '-short' },
        { src: '/images/clients/radio-canada.png', modifier: '-short' },
        { src: '/images/clients/fetes-nouvelle-france.png' },
        { src: '/images/clients/noovo.png', modifier: '-extra-short' },
        { src: '/images/clients/bfly.png' },
        { src: '/images/clients/periscope.png', modifier: '-extra-short' },
        { src: '/images/clients/loto-qc.png', modifier: '-short' },
        { src: '/images/clients/cite-agricole.png' },
        { src: '/images/clients/desjardins.png', modifier: '-short' },
        { src: '/images/clients/creme-soda.png', modifier: '-short' },
        { src: '/images/clients/nuage-pantalon.png', modifier: '-short' },
        { src: '/images/clients/tele-mag.png', },
        { src: '/images/clients/double-tour.png', modifier: '-extra-short' },
        { src: '/images/clients/tele-quebec.png' },
        { src: '/images/clients/gros-becs.png' }
      ],
      activeLogos: [],
      standbyLogos: [],
      interval: null
    }
  },
  computed: {
    sliderSize() {
      if (this.$device.desktop) return 7
      if (this.$device.laptop) return 6
      if (this.$device.tabletH) return 5
      if (this.$device.tablet) return 4
      return 3
    }
  },
  watch: {
    sliderSize: {
      immediate: true,
      handler() {
        this.clearSlider()
        this.setupSlides()
        this.startSlider()
      }
    }
  },
  beforeDestroy() {
    this.clearSlider()
  },
  methods: {
    setupSlides() {
      const logos = this.logos.length <= this.sliderSize ? [...this.logos, ...this.logos] : this.logos;
      this.activeLogos = logos.slice(0, this.sliderSize)
      this.standbyLogos = logos.slice(this.sliderSize)
    },
    startSlider() {
      this.interval = setInterval(this.shiftLogos, 3000)
    },
    clearSlider() {
      if (!this.interval) return
      clearInterval(this.interval)
      this.interval = null
    },
    shiftLogos() {
      this.standbyLogos.push(this.activeLogos.shift())
      this.activeLogos.push(this.standbyLogos.shift())
    }
  }
}
</script>
