import scroll from 'scroll'

let ScrollUtilsVM = null

const { body } = document
const { requestAnimationFrame } = window
const defaultScrollingElement = document.scrollingElement || document.documentElement

export default {
  get global() {
    return ScrollUtilsVM
  },
  install(Vue, { scrollingElement = defaultScrollingElement, scrollEventTarget }) {
    ScrollUtilsVM = new Vue({
      name: 'ScrollVM',
      data() {
        return { el: scrollingElement, position: 0, mute: true }
      },
      created() {
        const scrollEventElement = scrollEventTarget || this.el
        let isQueued = true

        const fireScrollEvent = () => {
          if (!this.mute) this.$emit('scroll')
          if (!this.freeze) this.position = this.el.scrollTop
          isQueued = false
        }

        const queueScrollEvent = () => {
          if (isQueued) return
          requestAnimationFrame(fireScrollEvent)
          isQueued = true
        }

        fireScrollEvent()
        this.mute = false
        scrollEventElement.addEventListener('scroll', queueScrollEvent)
      },
      methods: {
        prevent() {
          const { position } = this
          this.mute = true
          body.style.position = 'fixed'
          body.style.top = `-${position}px`

          return () => {
            this.mute = false
            body.style.position = ''
            body.style.top = ''
            this.to(position)
          }
        },
        on(handler) {
          return this.$on('scroll', handler)
        },
        off(handler) {
          return this.$off('scroll', handler)
        },
        to(targetPosition = 0, offset = 0, duration) {
          if (duration) scroll.top(this.el, targetPosition - offset, { duration })
          else this.el.scrollTop = targetPosition - offset
        },
        toTop(offset, duration) {
          this.to(0, offset, duration)
        },
        toBottom(offset, duration) {
          const { scrollHeight = 0 } = this.el
          this.to(scrollHeight, offset, duration)
        },
        toTarget(target, offset, duration) {
          const targetElement = typeof target === 'string' ? this.el.querySelector(target) : target
          if (!targetElement) return

          const { scrollTop } = this.el
          const targetTop = targetElement.getBoundingClientRect().top
          this.to(scrollTop + targetTop, offset, duration)
        }
      }
    })

    Vue.prototype.$scroll = ScrollUtilsVM

    Vue.mixin({
      mounted() {
        if (!this.$options.isPage) return
        this.$nextTick(() => {
          if (!this.$route.hash) return this.$scroll.toTop()
          this.$scroll.toTarget(this.$route.hash, 50, 350)
        })
      }
    })
  }
}
