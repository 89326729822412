<template>
  <article id="expertise" class="layout-grid">
    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Menuiserie & ébénisterie</h3>
      </div>

      <p>Travail du bois, débitage, assemblage, finition. Structure, petits mobiliers, présentoire de musée, etc.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Métaux</h3>
      </div>

      <p>Travail du métal dans un but structurel, ornemental ou mécanique comprenenant la découpe, roulage, assemblage (soudure, mécanique).</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Sculpture & moulage</h3>
      </div>

      <p>Sculpture pour l'interieur et/ou l'exterieur. Décors sculptés, personnages, petits objets décoratifs, etc.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Peinture & finition</h3>
      </div>

      <p>Peinture de finition, peinture trompe-l’œil, faux-fini, toile peinte, texture, etc.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Textile</h3>
      </div>

      <p>Gestion en atelier. Rideaux de scène, écran de projection, toiles diverses, etc. en partenariat.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Découpe numérique</h3>
      </div>

      <p>Gestion et finition en atelier. Découpe en partenariat.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Affichage & visuel</h3>
      </div>

      <p>Impression et installation d'affiches, enseignes, lettrage et autres produits corporatif.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Installation & démontage</h3>
      </div>

      <p>Service d'installation et de démontage de décors interieur et exterieur à travers le Québec.</p>
    </section>

    <section class="layout-grid__item">
      <div class="layout-grid__hgroup">
        <span />
        <h3 class="layout-grid__title">Et bien plus encore</h3>
      </div>

      <p>Ce que vous pouvez imaginer, on peut le realiser! <a class="link -p" href="#contact">Contacter nous</a> pour plus d'information.</p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'HomeExpertise'
}
</script>
