<template>
  <header id="home" class="layout-section -row -spacing">
    <div class="preloader">
      <figure
        v-for="img in images"
        :key="img"
        :style="{ backgroundImage: `url(${img})` }"
      />
    </div>

    <figure class="layout-section__figure" :style="backgroundImage" />

    <section class="layout-section__split">
      <h1 class="layout-section__banner">Conception Alain Gagné inc. réalise des décors adaptés à <span>votre représentation</span></h1>
      <p>Que se soit pour un décor de scène, théâtre, télévision, événements, corporatifs ou pour toute autre forme d’art, nous sommes désireux d’écouter votre projet afin de le concevoir selon vos attentes et votre budget.</p>

      <div class="layout-section__cta">
        <button class="button -dark" @click="$scroll.toTarget('#about', 50, 300)">En savoir plus</button>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'HomeHeader',
  data() {
    return {
      interval: null,
      currentImage: 0,
      images: [
        '/images/slider/slide-1.jpg',
        '/images/slider/slide-2.jpg',
        '/images/slider/slide-3.jpg',
        '/images/slider/slide-4.jpg',
        '/images/slider/slide-5.jpg',
        '/images/slider/slide-6.jpg',
        '/images/slider/slide-7.jpg',
        '/images/slider/slide-8.jpg',
      ]
    }
  },
  computed: {
    backgroundImage() {
      return { backgroundImage: `url(${this.images[this.currentImage]})` }
    }
  },
  mounted() {
    this.interval = setInterval(this.nextImage, 2500);
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    nextImage() {
      this.currentImage = (this.currentImage + 1) % this.images.length
    }
  }
}
</script>
