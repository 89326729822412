import Vue from 'vue'
import VueRouter from 'vue-router'
import { Home, Portfolio } from '@/views'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: '-active',
  // scrollBehavior: (to, from, savedPosition) => (to.hash ? { selector: to.hash } : savedPosition || null),
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home
    },
    {
      name: 'portfolio',
      path: '/portfolio',
      component: Portfolio
    }
  ]
})

export default router
