<template>
  <article class="layout-section">
    <h2 class="layout-section__title">Quelques projets dont nous sommes fiers</h2>
  </article>
</template>

<script>
export default {
  name: 'HomeProjects'
}
</script>
