<template>
  <article class="layout-section">
    <h2 class="layout-section__title">Notre portfolio est en construction</h2>
    <p>Voici quelques images pour votre inspiration!</p>

    <section class="layout-gallery">
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-1.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-2.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-3.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-4.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-5.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-6.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-7.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-8.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-9.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-10.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-11.jpg');" />
      <figure class="layout-gallery__item" style="background-image: url('/images/gallery/gallery-12.jpg');" />
    </section>
  </article>
</template>

<script>
export default {
  name: 'Portfolio',
  isPage: true
}
</script>
