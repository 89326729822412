<template>
  <menu class="navigation-top">
    <nav class="navigation-top__container">
      <router-link :to="{ name: 'home' }" class="navigation-top__logo">
        <img class="navigation-top__img" src="/images/Logo_CAG.png" alt="Logo Conception Alain Gagné" />
      </router-link>

      <ul class="navigation-top__list">
        <router-link :to="{ name: 'portfolio' }" class="navigation-top__item">
          Portfolio
        </router-link>

        <router-link :to="{ name: 'home', hash: '#contact' }" class="button -small -light">
          Nous contacter
        </router-link>
      </ul>

      <!-- <div class="navigation-top__burger" @click="$emit('toggle-sidenav')">
        <svg-icon id="menu" />
      </div> -->
    </nav>
  </menu>
</template>

<script>
export default {
  name: 'NavigationTop'
}
</script>
