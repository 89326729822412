<template>
  <footer class="navigation-footer">
    <section class="navigation-footer__row">
      <ul class="navigation-footer__list">
        <li class="navigation-footer__item">735 Avenue Newton</li>
        <li class="navigation-footer__item">Québec, QC</li>
        <span class="navigation-footer__item -hide">-</span>
        <li class="navigation-footer__item">Ouvert du lundi au vendredi</li>
        <li class="navigation-footer__item">8h00 à 17h00</li>
      </ul>

      <ul class="navigation-footer__list">
        <a href="tel:418-521-5752" class="navigation-footer__item -link">418-521-5752</a>
        <a href="mailto:alaingagne@decors.ca" class="navigation-footer__item -link">alaingagne@decors.ca</a>
        <span class="navigation-footer__item -hide">-</span>
        <a href="https://facebook.com/conceptionalaingagne/" target="_blank" rel="noopener" class="navigation-footer__item -link">Facebook</a>
        <a href="https://www.instagram.com/ateliercag/" target="_blank" rel="noopener" class="navigation-footer__item -link">Instagram</a>
      </ul>

      <ul class="navigation-footer__list">
        <a href="#home" class="navigation-footer__item -link">À propos</a>
        <router-link to="/portfolio" class="navigation-footer__item -link">Notre porfolio</router-link>
      </ul>
    </section>

    <section class="navigation-footer__copyright">
      <p>{{ currentYear }} &copy; Conception Alain Gagné - Tous droits réservés.</p>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'NavigationFooter',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      links: [
        { name: 'home', label: 'Home' },
        { name: 'about', label: 'About' }
      ]
    }
  }
}
</script>
